const readyEvent = document.createEvent('HTMLEvents');
readyEvent.initEvent('ready', false, false);

let triggered = false;

function ready() {
	if (triggered) return;
	triggered = true;

	document.dispatchEvent(readyEvent);
}

if (document.readyState === 'interactive') ready();
window.addEventListener('DOMContentLoaded', ready);
